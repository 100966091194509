import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import Foci from "../components/Foci";

const About = ({ data }) => {
    const pageData = data.airtable.data;
    const pageContent = data.airtable.data.postMarkdown.childMarkdownRemark.html;
    const imageUrl = pageData.image ? pageData.image.localFiles[0].childImageSharp.fluid.src : "";
        return (
          <Layout>
            <div className="index-container" > 
              <Helmet title={config.siteTitle} />
              <SEO />
              <h1 style={{marginBottom: `0`}}>About Us</h1>
              <h3>We are a climate change community impact laboratory.</h3>
              <div dangerouslySetInnerHTML={{ __html: pageContent}} />
              <hr className="zigzag" /> 
              <h2 style={{textAlign: `center`, marginBottom: `0`}} id="lab-reports">LAB REPORTS</h2>
              <Foci />
            </div>
          </Layout>
        )
}

export const query = graphql`
  {
    airtable(data: {slug: {eq: "about_page"}}) {
      data {
        title
        slug
        date
        status
        author
        image {
          localFiles {
            childImageSharp {
              fixed {
                src
              }
              fluid {
                src
              }
            }
          }
        }
        postMarkdown {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default About